<template>
  <div class="create">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div class="view_top">
          <h2 class="h_title">
            <span>POI 업로드</span>
          </h2>
          <a href="/poi_list" class="btn size1">목록보기</a>
        </div>
        <div class="fx-1">
          <!-- table_type -->
          <div class="table_type3" @change="json_preview()">
            <table>
              <colgroup>
                <col width="20%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <input value="관광타입" class="inputbox" readonly />
                  </th>
                  <td>
                    <div class="fx-2">
                      <select @change="set_type($event)" id="" name="" class="selectbox">
                        <option value="관광지">관광지</option>
                        <option value="문화시설">문화시설</option>
                        <option value="음식점">음식점</option>
                        <option value="레포츠">레포츠</option>
                        <option value="숙박">숙박</option>
                        <option value="쇼핑">쇼핑</option>
                        <option value="편의오락시설">편의오락시설</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr v-for="t in type" :key="t">
                  <th scope="row">
                    <input v-model="t[0]" class="inputbox" readonly />
                  </th>
                  <td>
                    <div class="fx-2">
                      <!-- <input v-if="is_combine('개요', t[0]) == true" id="" name="" class="inputbox">
                      <textarea v-else id="" name="" class="textareabox"></textarea> -->
                      <div v-if="
                        is_combine('개요', t[0]) || is_combine('소개', t[0])
                      " style="width: 100%">
                        <textarea id="" name="" class="textareabox" v-model="t[1]"></textarea>
                      </div>
                      <div v-else style="width: 100%">
                        <input id="" name="" class="inputbox" v-model="t[1]" />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <input v-model="addkey" type="text" class="inputbox" />
                  </th>
                  <td>
                    <div class="fx-2">
                      <input v-model="addvalue" type="text" class="inputbox" />
                      <a @click="addmeta" class="btn">추가</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="json_box">
            json_preview<br /><br />
            <pre>{{ json }}</pre>
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a @click="submit()" class="btn bg1">확인</a>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "Create",
  data() {
    return {
      addkey: "",
      addvalue: "",
      json: {},
      type_set: {
        관광지: [
          ["관광지명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        문화시설: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        음식점: [
          ["음식점명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["영업시간", ""],
          ["휴무일", ""],
          ["취급메뉴", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        레포츠: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        숙박: [
          ["업소명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["입실시간", ""],
          ["퇴실시간", ""],
          ["홈페이지 주소", ""],
          ["리뷰키워드", ""],
        ],
        쇼핑: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["영업시간", ""],
          ["휴무일", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        편의오락시설: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
      },
      type: [],
      temp_type: "관광지",
    };
  },
  components: {
    Header,
  },
  methods: {
    get_entity(mykey) {
      // entity 체크
      let entities = ["관광지명", "시설명", "음식점명", "업소명"];
      if (entities.indexOf(mykey) == -1) return false;
      else return true;
    },
    is_combine(s, str) {
      // 단어 포함 체크
      if (str.includes(s)) return true;
      else return false;
    },
    set_type(type) {
      // 관광타입 설정
      this.temp_type = type.target.value;
      this.type = this.type_set[type.target.value];
      this.json_preview();
    },
    addmeta() {
      // 항목 추가
      this.type.push([this.addkey, this.addvalue]);
      this.addkey = "";
      this.addvalue = "";
      this.json_preview();
    },
    removemeta(idx) {
      // 항목 제거
      this.type.splice(idx, 1);
      this.json_preview();
    },
    get_lat_lng() {
      return [1, 2];
    },
    async submit() {
      // POI 생성
      var metas = this.type;
      var address = "";
      metas.push(["관광타입", this.temp_type]);

      let address_index = 0;
      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta[0] == "주소") {
          address_index = i;
          address = meta[1];
          break;
        }
      }

      var address_info = await this.address_search(address);

      if (address_info.address) {
        metas.splice(address_index + 1, 0, [
          "주소(지번주소)",
          address_info.address,
        ]);
      }
      if (address_info.road_address) {
        metas.splice(address_index + 1, 0, [
          "주소(도로명)",
          address_info.road_address,
        ]);
      }
      let ll = address_info.coord;

      axios({
        url: config.apiurl + "?part=poi&mode=create",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        data: {
          token: this.$store.state.token,
          latlng: ll,
          metas: metas,
        },
      })
        .then(() => {
          // console.log(response);
          alert("등록되었습니다");
          this.$router.push({ name: "PoiList" });
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    async address_search(address) {
      return new Promise((resolv, reject) => {
        let result = {};
        if (address == "") {
          result["coord"] = [0, 0];
          resolv(result);
        } else {
          var geocoder = new kakao.maps.services.Geocoder();

          geocoder.addressSearch(address, (result, status) => {
            if (result.length == 0) {
              result["coord"] = [0, 0];
              resolv(result);
            } else {
              if (Object.prototype.hasOwnProperty.call(result[0], "address") && result[0].address !== null) { // 지번주소
                result["address"] = result[0].address.address_name;
              }
              if (Object.prototype.hasOwnProperty.call(result[0], "road_address") && result[0].road_address !== null) { // 도로명주소
                result["road_address"] = result[0].road_address.address_name;
              }

              if (status === kakao.maps.services.Status.OK) {
                // console.log("해당 좌표 : " + result[0].y + ", " + result[0].x);
                result["coord"] = [result[0].y, result[0].x];
                resolv(result);
              } else {
                result["coord"] = [0, 0];
                reject(result);
                console.log("[좌표 없음]" + address);
              }
            }
          });
        }
      });
    },
    json_preview() {
      // json 형식 미리보기 세팅
      var arr = {};
      this.type.forEach(function (element) {
        arr[element[0]] = element[1];
      });
      this.json = JSON.stringify(arr, null, 4);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 11);
    this.type = this.type_set["관광지"];
    this.json_preview();

    if (window.kakao && window.kakao.maps) {
      // this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      // script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6d213af40222b5124ab4c81049cb37c4&libraries=services";
      document.head.appendChild(script);
    }
  },
};
</script>

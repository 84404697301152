<template>
  <div id="container">
    <!-- header -->
    <Header />
    <LayerPopup :active="popup.active" :title="popup.title" :results="popup.results" />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">
          <router-link :to="{ path: '/poi_list' }">POI 목록</router-link>
        </h2>
        <span style="color:blue;">※비고 : 최종수정에 할당된 POI는 관리 > '수정' 버튼이 비활성화됩니다.</span>
        <div class="search_wrapper">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
          <!-- <input
            type="text"
            class="inputbox"
            @input="doAdressSearch"
            placeholder="주소"
          />
          <div class="adressinfo"></div> -->
        </div>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col />
              <col />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">POI 번호</th>
                <th scope="col">이름</th>
                <th scope="col">주소</th>
                <th scope="col">관리</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="poi_list.length == 0">
                <td colspan="5">
                  <span>{{ search_now }}로 검색된 결과가 없습니다</span>
                </td>
              </tr>
              <tr v-for="(poi, index) in poi_list" :key="poi.no">
                <td>{{ poi_numbering(index) }}</td>
                <td>{{ poi.no }}</td>
                <td class="txL">{{ poi.poi_name }}</td>
                <td class="txL">{{ poi.poi_address }}</td>
                <td>
                  <router-link :to="{ path: '/poi_view/' + poi.no }" class="btn size1">POI보기</router-link>
                  <template v-if="(poi.can_edit == true)">
                    <router-link :to="{ path: '/poi_edit/' + poi.no }" class="btn size1">수정</router-link>
                  </template>
                  <a @click="list_delete(poi.no)" class="btn size1">삭제</a>
                  <a @click="download(poi.no)" id="down_button2" class="btn size1">다운로드</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
          <!-- &nbsp;
          <a @click="test()" class="btn size1">테스트</a> -->
        </div>
        <!-- //table_type -->
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <div class="pois">
            <input type="file" accept=".csv" id="pois" v-on:change="submit_pois()" multiple style="display: none" />
            <label for="pois" class="btn bg1">POIs 추가</label>
          </div>
          <router-link :to="{ path: '/poi_create' }" class="btn bg1">POI 추가</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import JSZip from "jszip";
import saveAs from "file-saver";
import Header from "../components/Header.vue";
import LayerPopup from "../components/LayerPopup.vue";
import common from "../common/common.js";
import config from "../common/config.js";

const csv = require("csvtojson");

export default {
  name: "PoiList",
  data: function () {
    return {
      permno: 11,

      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,
      page: 1,
      page_total: 1,
      limit: 20,
      poi_num: 1,
      poi_list: [],
      submit_result: [],
      popup: {
        active: false,
        title: "Results...",
        results: [],
      },
    };
  },
  components: {
    Header,
    LayerPopup,
  },
  methods: {
    doAdressSearch(e) {
      this.address_search(e.target.value);
    },
    paging(count, to = false) {
      // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.list_load();
      // else if(c < 1){
      //   this.page = 1;
      // }else{
      //   this.page = this.page_total;
      // }
    },
    poi_numbering(num) {
      // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - (this.page - 1) * this.limit - num;
      } else {
        return this.poi_num - (this.page_total - 1) * this.limit - num;
      }
    },
    list_load() {
      this.poi_list = [];
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      axios({
        url: config.apiurl + "?part=poi&mode=list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          search: search,
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.poi_list = response.data.list;
          this.poi_num = response.data.poi_num;
          this.page_total = response.data.page_total;

          common.set_page_info(this, this.$route.name, this.page, search);
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log(error);
        });
    },
    list_delete(poi_no) {
      if (
        confirm(
          "해당 POI를 정말로 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다."
        )
      ) {
        axios({
          url: config.apiurl + "?part=poi&mode=delete2",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            no: poi_no,
          },
        })
          .then((response) => {
            alert("삭제되었습니다.");
            console.log(response);
            this.list_load();
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log("에러야!");
            console.log(error);
          });
      }
    },
    download(poi_no) {
      if (confirm("해당 POI의 정보를 다운로드 받으시겠습니까?")) {
        var zip = new JSZip();

        axios({
          url: config.apiurl + `?part=poi&mode=download3&no=${poi_no}`,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "get",
        })
          .then((response) => {
            // main POI json
            var arr = {};
            let metas = response.data.metas;
            metas.forEach(function (element) {
              arr[element.poi_key] = element.poi_value;
            });
            let metasjson = JSON.stringify(arr, null, 4);
            zip.file("main.json", metasjson);

            // sub metas json
            var file_names = [];
            let sub_metas = response.data.sub_metas;
            var count = 1;
            sub_metas.forEach(function (sub_element) {
              var sub_filename = "subs_" + count + ".json";
              if (sub_element.filename != "") {
                sub_filename = sub_element.filename + ".json";
              }

              file_names.push(sub_filename);

              let metasjson2 = JSON.stringify(sub_element, null, 4);
              zip.file(sub_filename, metasjson2);
              count++;
            });

            // images
            var c = 0;
            let images = response.data.sub_image;
            images.forEach(function (element) {
              var ld = element.filename.lastIndexOf(".");
              var ext = element.filename.substring(
                ld + 1,
                element.filename.length
              );

              let rawImg = element.rawImg;
              var image_name = file_names[c];
              image_name = image_name.replace("json", ext);
              zip.file(image_name, rawImg.replace(/data:.*?;base64,/, ""), {
                base64: true,
              });
              c++;
            });

            // zip download
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, poi_no + ".zip");
            });
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(3)");
            console.log("에러야!");
            console.log(error);
          });
      }
    },
    async submit_pois() {
      this.popup.active = true;
      let pois = document.getElementById("pois");
      const files = pois.files;

      for (let i = 0; i < files.length; i++) {
        let data = await files[i].text();

        let csvdata = await csv({
          noheader: true,
        }).fromString(data);
        let poi = [];
        for (let j = 0; j < csvdata.length; j++) {
          poi.push([csvdata[j]["field1"], csvdata[j]["field2"]]);
        }

        let address = "";
        let address_index = 0;
        for (let i = 0; i < poi.length; i++) {
          const meta = poi[i];
          if (
            ["주소", "주소(도로명)", "주소(지번주소)", "주소(도로명/지번주소)"].indexOf(
              meta[0]
            ) !== -1
          ) {
            address = meta[1];
            poi[i] = ["주소", meta[1]];
            address_index = i;
            break;
          }
        }

        try {
          var address_info = await this.address_search(address);

          if (address_info.address) {
            poi.splice(address_index + 1, 0, [
              "주소(지번주소)",
              address_info.address,
            ]);
          }
          if (address_info.road_address) {
            poi.splice(address_index + 1, 0, [
              "주소(도로명)",
              address_info.road_address,
            ]);
          }
          let ll = address_info.coord;
          let response = await axios({
            url: config.apiurl + "?part=poi&mode=create3",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "post",
            data: {
              token: this.$store.state.token,
              latlng: ll,
              metas: poi,
            },
          });

          console.log(response);
          this.popup.results.push({
            result: "성공",
            filename: files[i].name,
          });
        } catch (error) {
          let response = await axios({
            url: config.apiurl + "?part=poi&mode=create3",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "post",
            data: {
              token: this.$store.state.token,
              latlng: [0, 0],
              metas: poi,
            },
          });
          console.log(response);
          this.popup.results.push({
            result: `실패`,
            error: `(주소검색 실패)`,
            filename: files[i].name,
          });
          console.log("에러야!");
          console.log(error);
        }
      }
    },
    async address_search(address) {
      console.log(address);
      return new Promise((resolv, reject) => {
        let result = {};
        if (address == "") {
          result["coord"] = [0, 0];
          resolv(result);
        } else {
          var geocoder = new kakao.maps.services.Geocoder();

          geocoder.addressSearch(address, (result, status) => {
            if (result.length == 0) {
              result["coord"] = [0, 0];
              resolv(result);
            } else {
              if (
                Object.prototype.hasOwnProperty.call(result[0], "address") &&
                result[0].address !== null
              ) {
                result["address"] = result[0].address.address_name;
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  result[0],
                  "road_address"
                ) &&
                result[0].road_address !== null
              ) {
                result["road_address"] = result[0].road_address.address_name;
              }

              if (status === kakao.maps.services.Status.OK) {
                // console.log("해당 좌표 : " + result[0].y + ", " + result[0].x);
                result["coord"] = [result[0].y, result[0].x];
                resolv(result);
              } else {
                result["coord"] = [0, 0];
                reject(result);
                console.log("[좌표 없음]" + address);
              }
            }
          });
        }
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.list_load();
      }, 250);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 11);
    await common.set_page(this);

    this.list_load();

    // if (window.kakao && window.kakao.maps) {
    // this.initMap();
    // } else {
    const script = document.createElement("script");
    /* global kakao */
    // script.onload = () => kakao.maps.load(this.initMap);
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6d213af40222b5124ab4c81049cb37c4&libraries=services";
    document.head.appendChild(script);
    // }
  },
};
</script>


<style>
.search_wrapper {
  padding: 12px;
}

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>
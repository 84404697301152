<template>
  <div class="edit">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div class="view_top">
          <h2 class="h_title">
            <span>POI 수정</span>&nbsp;
          </h2>
          <a href="/poi_list" class="btn size1">목록보기</a>
        </div>
        <a @click="resort()" class="btn size1">재정렬하기</a>
        <div class="fx-1 mgT10">
          <!-- table_type -->
          <div class="table_type3" @change="json_preview()">
            <table>
              <colgroup>
                <col width="20%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <input value="관광타입" class="inputbox" readonly />
                  </th>
                  <td>
                    <div class="fx-2">
                      <select @change="set_type($event)" v-model="ttype" class="selectbox">
                        <option value="관광지">관광지</option>
                        <option value="문화시설">문화시설</option>
                        <option value="음식점">음식점</option>
                        <option value="레포츠">레포츠</option>
                        <option value="숙박">숙박</option>
                        <option value="쇼핑">쇼핑</option>
                        <option value="편의오락시설">편의오락시설</option>
                        <option value="편의오락">편의오락</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr v-for="t in type" :key="t">
                  <th scope="row">
                    <input class="inputbox" v-model="t[0]" readonly />
                  </th>
                  <td>
                    <div class="fx-2">
                      <input class="inputbox" v-model="t[1]" :readonly="check_address(t)"
                        :class="[check_address(t) ? 'readonly' : '']" />
                      <!-- <a v-if="
                        !(
                          t[0] == '주소' ||
                          t[0] == '주소(도로명)' ||
                          t[0] == '주소(지번주소)'
                        )
                      " @click="removemeta(idx)" class="btn">제거</a> -->
                    </div>
                  </td>
                </tr>



                <!-- <tr>
                  <th scope="row">
                    <input v-model="addkey" type="text" class="inputbox" />
                  </th>
                  <td>
                    <div class="fx-2">
                      <input v-model="addvalue" type="text" class="inputbox" />
                      <a @click="addmeta()" class="btn">추가</a>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- //table_type -->
          <div class="json_box">
            json preview<br /><br />
            <pre>{{ json }}</pre>
          </div>
        </div>

        <div class="view_top mgT50">
          <h2 class="h_title">
            <span>Triple</span>
          </h2>
        </div>
        <div class="fx-1">
          <!-- table_type -->
          <div class="table_type3">
            <table>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>A</th>
                  <th>R</th>
                  <th>B</th>
                </tr>
                <tr v-if="triple.length == 0">
                  <td colspan="3" style="text-align:center;">등록된 Triple이 없습니다.</td>
                </tr>
                <tr v-for="t in triple" :key="t">
                  <td>{{ t.a }}</td>
                  <td>{{ t.r }}</td>
                  <td>{{ t.b }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //table_type -->
        </div>

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a v-if="edited == 0" @click="submit()" class="btn bg1">POI수정</a>
          <span v-else>저장중입니다...</span>
        </div>

      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "Edit",
  data() {
    return {
      origin_address: "",
      poi_no: "",
      addkey: "",
      addvalue: "",
      type: [],
      json: {},
      ttype: "", // 관광타입
      ll: "", // 위도경도
      resorted: 0,
      triple: [],
      edited: 0,
      type_preset: {
        관광지: [
          ["관광지명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        문화시설: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        음식점: [
          ["음식점명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["영업시간", ""],
          ["휴무일", ""],
          ["취급메뉴", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        레포츠: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        숙박: [
          ["업소명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["입실시간", ""],
          ["퇴실시간", ""],
          ["홈페이지 주소", ""],
          ["리뷰키워드", ""],
        ],
        쇼핑: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["영업시간", ""],
          ["휴무일", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        편의오락시설: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        편의오락: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
      },
    };
  },
  components: {
    Header,
  },
  methods: {
    resort() {
      if (this.type_preset[this.ttype] != undefined) {
        if (confirm("[" + this.ttype + "] 관광타입의 프리셋에 따라 재정렬하시겠습니까?")) {
          var temp_type = this.type_preset[this.ttype];
          temp_type.forEach(element => {
            for (let i = 0; i < this.type.length; i++) {
              if (this.type[i][0] == element[0]) {
                element[1] = this.type[i][1];
                break;
              }
            }
          });
          console.log(temp_type)
          this.type = temp_type;
          this.resorted = 1;
        }
      } else {
        alert("[" + this.ttype + "] 관광타입에 대한 프리셋이 없습니다. 직접 관광타입을 선택해주세요.");
      }
    },
    check_address(t) {
      if (t[0] == "주소(도로명)" || t[0] == "주소(지번주소)") return true;
      else return false;
    },
    async address_change() {
      var metas = this.type;
      var address = "";
      let address_index = 0;

      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta[0] == "주소") {
          address_index = i;
          address = meta[1];
          break;
        }
      }

      var address_info = await this.address_search(address);

      if (address_info.address) {
        if (this.resorted == 1) { // 재정렬되었으면 추가
          metas.splice(address_index + 1, 0, [
            "주소(지번주소)",
            address_info.address,
          ]);
        } else {
          for (let i = 0; i < metas.length; i++) {
            if (metas[i][0] == "주소(지번주소)") {
              metas[i][1] = address_info.address;
            }
          }
        }
      }
      if (address_info.road_address) {
        if (this.resorted == 1) { // 재정렬되었으면 추가
          metas.splice(address_index + 1, 0, [
            "주소(도로명)",
            address_info.road_address,
          ]);
        } else {
          for (let i = 0; i < metas.length; i++) {
            if (metas[i][0] == "주소(도로명)") {
              metas[i][1] = address_info.road_address;
            }
          }
        }
      }
      this.ll = address_info.coord;
    },
    addmeta() {
      this.type.push([this.addkey, this.addvalue]);
      this.addkey = "";
      this.addvalue = "";
    },
    removemeta(idx) {
      this.type.splice(idx, 1);
    },
    async submit() {
      this.edited = 1;
      var type = this.type;
      let metas = type;
      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta[0] == "주소" && (this.origin_address != meta[1] || this.resorted == 1)) {
          await this.address_change();
        }
      }
      metas.unshift(["관광타입", this.ttype]);

      axios({
        url: config.apiurl + "?part=poi&mode=edit",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_no: this.poi_no,
          metas: metas,
          latlng: this.ll,
        },
      })
        .then((response) => {
          console.log(response);
          alert("수정되었습니다.");
          this.$router.push({ name: "PoiList" });
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    set_type(type) {
      // 관광타입 설정
      this.type = this.type_preset[type.target.value];
      this.json_preview();
    },
    load_data() {
      axios({
        url: config.apiurl + "?part=poi&mode=edit_data",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_no: this.$route.params.no,
        },
      })
        .then((response) => {
          let lists = response.data.list;
          for (let i = 0; i < lists.length; i++) {
            if (lists[i][0] == "관광타입") {
              this.ttype = lists[i][1];
              lists.splice(i, 1);
            } else if (lists[i][0] == "주소") {
              this.origin_address = lists[i][1];
            }
          }
          this.type = lists;
          this.json_preview();
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
    },
    json_preview() {
      // json 미리보기
      var arr = {};
      this.type.forEach(function (element) {
        arr[element[0]] = element[1];
      });
      this.json = JSON.stringify(arr, null, 4);
    },
    async address_search(address) {
      return new Promise((resolv, reject) => {
        let result = {};
        if (address == "") {
          result["coord"] = [0, 0];
          resolv(result);
        } else {
          var geocoder = new kakao.maps.services.Geocoder();

          geocoder.addressSearch(address, (result, status) => {
            if (result.length == 0) {
              result["coord"] = [0, 0];
              resolv(result);
            } else {
              if (
                Object.prototype.hasOwnProperty.call(result[0], "address") &&
                result[0].address !== null
              ) {
                // 지번주소
                result["address"] = result[0].address.address_name;
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  result[0],
                  "road_address"
                ) &&
                result[0].road_address !== null
              ) {
                // 도로명주소
                result["road_address"] = result[0].road_address.address_name;
              }

              if (status === kakao.maps.services.Status.OK) {
                // console.log("해당 좌표 : " + result[0].y + ", " + result[0].x);
                result["coord"] = [result[0].y, result[0].x];
                resolv(result);
              } else {
                result["coord"] = [0, 0];
                reject(result);
                console.log("[좌표 없음]" + address);
              }
            }
          });
        }
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 11);
    this.poi_no = this.$route.params.no;
    this.load_data();

    if (window.kakao && window.kakao.maps) {
      // this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      // script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6d213af40222b5124ab4c81049cb37c4&libraries=services";
      document.head.appendChild(script);
    }
  },
};
</script>

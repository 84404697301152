<template>
  <div class="view">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div class="view_top">
          <h2 class="h_title">
            <router-link :to="{ path: '/' }">POI 조회</router-link>
          </h2>
          <a href="/poi_list" class="btn size1">목록보기</a>
        </div>
        <div class="fx-1">
          <!-- table_type -->
          <div class="table_type3">
            <table>
              <colgroup>
                <col width="20%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-for="meta in metas" :key="meta.no">
                  <th scope="row">{{ meta[0] }}</th>
                  <td>
                    <div class="fx-2">
                      {{ meta[1] }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //table_type -->
          <div class="json_box">
            json preview<br /><br />
            <pre>{{ json }}</pre>
          </div>
        </div>

        <div class="fx-1 mgT60">
          <!-- table_type -->
          <div class="table_type3">
            <h2 class="h_title">
              <span>Triple</span>
            </h2>
            <table>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>A</th>
                  <th>R</th>
                  <th>B</th>
                </tr>
                <tr v-if="triple.length == 0">
                  <td colspan="3" style="text-align:center;">등록된 Triple이 없습니다.</td>
                </tr>
                <tr v-for="t in triple" :key="t">
                  <td>{{ t.a }}</td>
                  <td>{{ t.r }}</td>
                  <td>{{ t.b }}</td>
                </tr>
              </tbody>
            </table>

            <h2 class="h_title mgT50">
              <span>연관된 Triple</span>
            </h2>
            <table>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>POI 번호</th>
                  <th>A</th>
                  <th>R</th>
                  <th>B</th>
                </tr>
                <tr v-if="related_triple.length == 0">
                  <td colspan="4" style="text-align:center;">연관된 Triple이 없습니다.</td>
                </tr>
                <tr v-for="t in related_triple" :key="t">
                  <td>{{ t.poi_no }}</td>
                  <td>{{ t.a }}</td>
                  <td>{{ t.r }}</td>
                  <td>{{ t.b }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //table_type -->
          <div class="json_box">
            전체 json preview<br /><br />
            <pre>{{ json2 }}</pre>
          </div>
        </div>

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <router-link class="btn bg1" :to="{ path: '/poi_edit/' + no }">POI수정</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
// import JSZip from "jszip";
// import saveAs from "file-saver";

export default {
  name: "View",
  data() {
    return {
      no: 0,
      metas: [],
      json: {},
      triple: [],
      related_triple: [],
      mno: 0,
      total_data_list: {},
      json2: {},
    };
  },
  components: {
    Header
  },
  methods: {
    poi_load() {
      axios({
        url: config.apiurl + "?part=poi&mode=view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          no: this.$route.params.no,
        }
      }).then(async (response) => {
        let d = response.data;
        console.log(d);
        this.mno = d.mno;
        this.no = this.$route.params.no;
        this.metas = d.metas;
        this.triple = d.triple;
        this.related_triple = d.related_triple;

        var arr = {};
        this.metas.forEach(function (element) {
          arr[element[0]] = element[1];
        });
        this.json = JSON.stringify(arr, null, 4);

        this.total_data_list = d.total_data_list;

        await this.image_setting();
        // console.log(this.total_data_list)
        // this.json2 = JSON.stringify(this.total_data_list, null, 4);
        this.json2 = this.total_data_list;


      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    image_setting() {
      return new Promise((resolv) => {
        for (let i = 0; i < this.total_data_list.length; i++) {
          let imageinfo = this.total_data_list[i].imageInfo;
          let img = new Image();

          img.onload = () => {
            this.total_data_list[i]['imageInfo']['width'] = img.width;
            this.total_data_list[i]['imageInfo']['height'] = img.height;
          }
          img.src = 'https://' + imageinfo['image_path'];

          delete this.total_data_list[i]['imageInfo']['image_path'];
        }
        // console.log(this.total_data_list)
        resolv(1)
      })
    }
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, [11, 16]);
    this.poi_load();
  },
};
</script>
